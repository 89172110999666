import React, {useEffect, useState} from "react";
import {Loader} from "rsuite";
import {Link} from "react-router-dom";
import { removeProtocol } from "../utils/utils";

function VideoList() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);

    useEffect(() => {
        fetch("/api/v1/render/latest")
            .then(res => res.json())
            .then(
                (result) => {
                    setItems(result);
                    setIsLoaded(true);
                },
                (error) => {
                    setError(error);
                    setIsLoaded(true);
                }
            )
    }, [])

    if (error) {
        return <div>Videos cannot be loaded at this time, please try again later.</div>;
    } else if (!isLoaded) {
        return <Loader backdrop content="loading..." vertical/>;
    }

    return (
        <div>
            {items.map(item => (
                <Link to={"/view/" + item.job_id} className="videoThumbLink">
                    <div className="videoThumbBox">
                        <img
                            alt={item.repository_url}
                            src={item.thumbnail_url}
                            width="100%"
                            className="thumbImage"
                        />
                        <div className="videoThumbFooter">
                            {removeProtocol(item.repository_url)}
                        </div>
                    </div>
                </Link>
            ))}
        </div>
    );
}

function ViewSidebar() {
    return (
        <div>
            <VideoList/>
        </div>
    );
}

export default ViewSidebar;