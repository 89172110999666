import React, {useEffect, useState} from "react";
import {Loader, Message} from "rsuite";
import {Redirect} from "react-router-dom";

function LogoutView() {

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        fetch("/api/v1/logout")
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                },
                (error) => {
                    setError(error);
                    setIsLoaded(true);
                }
            )

    }, [])

    if (error) {
        return <div className="loginError">
            <Message
                showIcon
                type="error"
                title="Can't logout this time"
                description="Please try again later"
            />
        </div>
    } else if (!isLoaded) {
        return <Loader backdrop size="lg"/>
    }

    window.location = "/";

    return (
        <div>
            <Redirect to="/" />
        </div>
    )
}

export default LogoutView;
