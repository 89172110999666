import React, {useEffect, useState} from "react";
import ReactPlayer from "react-player";
import {useParams} from "react-router-dom";
import {normalizeProtocol, removeProtocol} from "../utils/utils";
import { DiscussionEmbed } from 'disqus-react';
import {Loader} from "rsuite";

function VideoPlayer(props) {

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [playVideo, setPlayVideo] = useState(true);

    useEffect(() => {
        fetch("/api/v1/render/" + props.jobId)
            .then(res => res.json())
            .then(
                (result) => {
                    setItems(result);
                    setIsLoaded(true);
                },
                (error) => {
                    setError(error);
                    setIsLoaded(true);
                }
            )

        return () => {
            setPlayVideo(false);
        }
    }, [props.jobId])

    if (error) {
        return <div>Video cannot be loaded at this time, please try again later.</div>;
    } else if (!isLoaded) {
        return <Loader backdrop content="loading..." vertical/>;
    }

    let config = {
        file: {
            attributes: {
                controlsList: 'nodownload',
                poster: items.thumbnail_url
            }
        }
    };

    let videoUrl = items.video_url;

    return (
        <div>
            <div className="player">
                <ReactPlayer
                    url={videoUrl}
                    playing={playVideo}
                    loop={false}
                    controls={true}
                    muted={true}
                    config={config}
                    width='100%'
                    height='100%'
                    className="videoPlayer"
                />
                <div className="videoThumbFooterBig">
                    <a href={normalizeProtocol(items.repository_url)}>
                        {removeProtocol(items.repository_url)}
                    </a>
                </div>
            </div>
            <div className="discussContainer">
                <DiscussionEmbed
                    shortname='visualsource'
                    config={
                        {
                            url: "https://www.visualsource.net/view/" +items.job_id,
                            identifier: items.job_id,
                            title: "Visualsource video: " + removeProtocol(items.repository_url)
                        }
                    }
                />
            </div>
        </div>
    );
}

function View() {
    let {jobId} = useParams();
    return (
        <div className="App">
            <VideoPlayer jobId={jobId}/>
        </div>
    )
}

export default View;