function removeProtocol(url) {
    if (url === undefined) {
        return "";
    }
    return url.replace(/(^\w+:|^)\/\//, '');
}

function addProtocol(url, protocol = 'https://') {
    return (protocol + url).trim();
}

function normalizeProtocol(url, protocol = 'https://') {
    return addProtocol(removeProtocol(url), protocol);
}

module.exports = {
    removeProtocol,
    normalizeProtocol
}
