import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { Redirect, Link, useParams } from "react-router-dom";
import { normalizeProtocol, removeProtocol } from "../utils/utils";
import { DiscussionEmbed } from "disqus-react";
import { Button, Loader, Message, Panel, Progress, Steps } from "rsuite";
import { SuperSEO } from "react-super-seo";
import VideoMetaData from "../utils/videoMeta";

function CommentView(props) {
  if (!props.allowed) {
    return <div />;
  }
  if (props.enabled) {
    let nakedUrl = removeProtocol(props.url);
    return (
      <div className="discussContainer">
        <DiscussionEmbed
          shortname="visualsource"
          config={{
            url: "https://www.visualsource.net/menu/repo/" + nakedUrl,
            identifier: nakedUrl,
            title: "Visualsource repository: " + nakedUrl,
          }}
        />
      </div>
    );
  }
  return (
    <div>
      <Button appearance="default" onClick={() => props.commentToggle()}>
        Show Comments
      </Button>
    </div>
  );
}

function ProgressDraw(props) {
  if (props.step === 1) {
    const { Circle } = Progress;
    let strokeColor = "#35C3FF";
    let percent = Math.round(
      (props.workerSeconds / props.estimatedRenderTime) * 100
    );
    if (percent > 90) {
      strokeColor = "#ffc107";
    }
    if (percent < 100) {
      let style = {
        width: 120,
        display: "inline-block",
        marginRight: 10,
      };
      // rendering step
      return (
        <div className="loaderClassPercent">
          <Circle percent={percent} style={style} strokeColor={strokeColor} />
        </div>
      );
    }
  }
  // for default step 0 we return with the infinite loader + time estimation
  return (
    <div className="loaderClass">
      <Loader
        speed="slow"
        size="lg"
        content={"Elapsed time: " + props.seconds}
        style={props.loaderStyle}
      />
    </div>
  );
}

function VideoPlayer(props) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [items, setItems] = useState([]);
  const [seconds, setSeconds] = useState(0);
  const [workerSeconds, setWorkerSeconds] = useState(0);
  const [commentView, setCommentView] = useState(false);
  const [playVideo, setPlayVideo] = useState(true);

  const nakedUrl = removeProtocol(props.repoUrl);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
      setWorkerSeconds((workerSeconds) => workerSeconds + 1);
    }, 1000);

    let fetchInterval;

    const fetchHandler = () =>
      fetch("/api/v1/account/active")
        .then((res) => res.json())
        .then(
          (result) => {
            setIsLoggedIn(result.active);
            fetch("/api/v1/private/repo/" + nakedUrl)
              .then((res) => res.json())
              .then(
                (result) => {
                  setItems(result);

                  if (result.status === "not found") {
                    fetchInterval = setTimeout(fetchHandler, 30000);
                    // repo not found, we'll request it now
                    fetch("/api/v1/private/render", {
                      headers: {
                        "Content-type": "application/json; charset=UTF-8",
                      },
                      method: "post",
                      body: JSON.stringify({
                        url: normalizeProtocol(nakedUrl),
                      }),
                    })
                      .then((res) => res.json())
                      .then(
                        (result) => {
                          setItems(result);
                          if (
                            result.last_time_range !== undefined &&
                            result.status === "video_error"
                          ) {
                            setSeconds(result.last_time_range);
                            setWorkerSeconds(result.last_worker_range);
                            clearInterval(interval);
                            clearTimeout(fetchInterval);
                          } else if (result.queued_time_range !== undefined) {
                            setSeconds(result.queued_time_range);
                            setWorkerSeconds(result.worker_time_range);
                          }
                          setIsLoaded(true);
                        },
                        (error) => {
                          setError(error);
                          setIsLoaded(true);
                        }
                      );
                  } else {
                    setIsLoaded(true);
                  }
                },
                (error) => {
                  setError(error);
                  setIsLoaded(true);
                }
              );
          },

          (error) => {
            setError(error);
            setIsLoggedIn(false);
          }
        );

    fetchHandler();

    return () => {
      clearInterval(interval);
      if (fetchInterval) {
        clearTimeout(fetchInterval);
      }
      setPlayVideo(false);
    };
  }, [nakedUrl]);

  if (error) {
    return (
      <div>Video cannot be loaded at this time, please try again later.</div>
    );
  } else if (!isLoaded) {
    return <Loader backdrop content="loading..." vertical />;
  } else if (isLoggedIn === false) {
    return (
        <Redirect to="/"/>
    )
  }

  if (isLoaded) {
    if (
      items.status === "video_error" ||
      items.status === "video_enqueued" ||
      items.status === "video_processing"
    ) {
      let loaderStyle = {};
      let type = "info";

      // default step
      let step = 0;
      let title = "Please wait, video is enqueued.";
      let description =
        "Rendering starts soon. Please wait, or feel free to bookmark this location and come back later when processing is finished.";
      let estimatedRenderTime = 0;

      if (items.repository_data) {
        step = 1;
        let repoData = items.repository_data;
        let dateFrom = repoData.date_from;
        let dateTo = repoData.date_to;
        let dateDiff = dateTo - dateFrom; // seconds
        let dateDiffDays = dateDiff / 60 / 60 / 24;
        estimatedRenderTime = dateDiffDays * 0.1 * 2.1; // we render one day at 0.1 second, at 30fps

        title = "Please wait, rendering video...";
        description = "Video processing could take several minutes.";
      }

      if (items.status === "video_error") {
        type = "error";
        title = "Processing error.";
        description = "We cannot process this repository this time.";

        if (items.message === "no repo access") {
          title = "Cannot access this repository.";
          description = (
            <div>
              If this is a private repository, please{" "}
              <Link to={"/login"} className="loginFirst">
                login first
              </Link>{" "}
              to access it.
            </div>
          );
        }

        if (items.message === "no member access") {
          title = "Cannot access this repository as a member.";
          description = (
            <div>
              If this is your repository, please make sure access is enabled in
              the{" "}
              <Link to={"/menu/repositories"} className="loginFirst">
                repositories
              </Link>
              . If it's a public repository, you might log out first to view it.
            </div>
          );
        }

        loaderStyle = { visibility: "hidden" };
        step = 2;
      }
      return (
        <Panel bordered className="renderingVideo">
          <div>
            <Steps current={step}>
              <Steps.Item title="Enqueued" />
              <Steps.Item title="Rendering" />
              <Steps.Item title="Finished" />
            </Steps>
          </div>
          <ProgressDraw
            step={step}
            seconds={seconds}
            workerSeconds={workerSeconds}
            estimatedRenderTime={estimatedRenderTime}
            loaderStyle={loaderStyle}
          />
          <Message
            showIcon
            type={type}
            title={title}
            description={description}
          />
        </Panel>
      );
    }
  }

  let config = {
    file: {
      attributes: {
        controlsList: "nodownload",
        poster: items.thumbnail_url,
      },
    },
    dailymotion: {
      params: {
        autoplay: true,
        controls: true,
        mute: true,
        "queue-autoplay-next": false,
        "queue-enable": false,
        "sharing-enable": false,
        "ui-logo": false,
      },
    },
  };

  let videoUrl = items.video_url;

  if (items.lbry_url !== null) {
    videoUrl = items.lbry_url;
  }

  if (items.dailymotion_url !== null) {
    videoUrl = items.dailymotion_url;
  }

  if (items.youtube_url !== null) {
    videoUrl = items.youtube_url;
  }

  let commentsAllowed = false;

  let toggleComments = function () {
    setCommentView(!commentView);
  };

  let pageTitle = "visualsource.net | " + removeProtocol(items.repository_url);
  let pageDescription = "Source Code History Visualization";

  return (
    <div>
      <SuperSEO
        title={pageTitle}
        description={pageDescription}
        lang="en"
        openGraph={{
          ogTitle: pageTitle,
          ogUrl:
            "https://www.visualsource.net/menu/repo/" +
            removeProtocol(items.repository_url),
          ogDescription: pageDescription,
          ogSiteName: "visualsource.net",
          fbAppId: "137889841303137",
          ogVideo: {
            ogVideo: videoUrl,
            ogVideoType: "video/mp4",
            ogVideoWidth: 1920,
            ogVideoHeight: 1080,
          },
          ogImage: {
            ogImage: items.thumbnail_url,
            ogImageAlt: removeProtocol(items.repository_url),
            ogImageWidth: 1920,
            ogImageHeight: 1080,
            ogImageType: "image/jpeg",
          },
        }}
        twitter={{
          twitterPlayerCard: {
            playerCardTitle: pageTitle,
            playerCardSiteUsername: "visualsourcenet",
            playerCardDescription: pageDescription,
            playerCardPlayerWidth: 1920,
            playerCardPlayerHeight: 1080,
            playerCardImage: items.thumbnail_url,
            playerCardImageAlt: removeProtocol(items.repository_url),
          },
        }}
      />
      <div className="player">
        <ReactPlayer
          url={videoUrl}
          playing={playVideo}
          loop={false}
          controls={true}
          muted={true}
          config={config}
          width="100%"
          height="100%"
          className="videoPlayer"
          pip={false}
        />
        <div className="videoThumbFooterBig">
          <a
            href={normalizeProtocol(items.repository_url)}
            target="_blank"
            rel="noreferrer"
          >
            <Button className="repoViewButton" appearance="default">
              {nakedUrl}
            </Button>
          </a>
          <div className="repoViewMetaData">
            <VideoMetaData video={items} />
          </div>
        </div>
      </div>
      <CommentView
        allowed={commentsAllowed}
        enabled={commentView}
        url={items.repository_url}
        commentToggle={() => toggleComments(this)}
      />
    </div>
  );
}

function MenuRepoView() {
  let { repo } = useParams();
  return (
    <div className="App">
      <SuperSEO
        title={"visualsource.net | " + removeProtocol(repo)}
        description={"Source Code History Visualization"}
        lang="en"
      />
      <VideoPlayer repoUrl={removeProtocol(repo)} />
    </div>
  );
}

export default MenuRepoView;
