import React, {useEffect, useState} from "react";
import {FlexboxGrid, Col, Loader} from "rsuite";
import {Link} from "react-router-dom";
import {removeProtocol} from "../utils/utils";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import VideoMetaData from "../utils/videoMeta";

function VideoList() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);

    useEffect(() => {
        fetch("/api/v1/render/latest")
            .then(res => res.json())
            .then(
                (result) => {
                    setItems(result);
                    setIsLoaded(true);
                },
                (error) => {
                    setError(error);
                    setIsLoaded(true);
                }
            )
    }, [])

    if (error) {
        return <div>Videos cannot be loaded at this time, please try again later.</div>;
    } else if (!isLoaded) {
        return <Loader backdrop content="loading..." vertical/>;
    }

    return (
        <FlexboxGrid>
            {items.map(item => (
                <FlexboxGrid.Item key={item.job_id} componentClass={Col} colspan={24} md={8}>
                    <Link to={"/repo/" + removeProtocol(item.repository_url)} className="videoThumbLink">
                        <div className="videoThumbBox">
                            <div className="videoThumbFooter">
                                <div className="videoRepoText">
                                    {removeProtocol(item.repository_url)}
                                </div>
                            </div>
                            <div className="thumbFrame">
                                <LazyLoadImage
                                    alt={item.repository_url}
                                    src={item.thumbnail_url}
                                    width="100%"
                                    effect="blur"
                                    className="thumbImage"
                                />
                            </div>
                            <div className="videoThumbFooter">
                                <VideoMetaData video={item}/>
                            </div>
                        </div>
                    </Link>
                </FlexboxGrid.Item>
            ))}
        </FlexboxGrid>
    );
}

function Home() {
    return (
        <div>
            <VideoList/>
        </div>
    );
}

export default Home;