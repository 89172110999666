import React, {Suspense, useEffect} from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation
} from 'react-router-dom';

import {
    Container,
    Content,
    Header, Loader,
    Sidebar
} from 'rsuite';

import {useCookies} from 'react-cookie';

import VisualSourceNavbar from "./components/visualsourcenavbar";
import Home from "./views/home";
import View from "./views/view";
import VisualSourceFooter from "./components/visualsourcefooter";
import ViewSidebar from "./views/viewsidebar";
import RepoView from "./views/repoView";
import MenuRepoView from "./views/menuRepoView";
import CreateVideo from "./views/createvideo";
import Analytics from 'react-router-ga';
import LoginView from "./views/login";
import LogoutView from "./views/logout";

const Docs = React.lazy(() => import('./views/pages/docs'));
const Terms = React.lazy(() => import('./views/pages/terms'));
const Status = React.lazy(() => import('./views/pages/status'));
const Privacy = React.lazy(() => import('./views/pages/privacy'));

const MenuView = React.lazy(() => import('./views/menu'));
const MenuAccountView = React.lazy(() => import('./views/menuAccountView'));
const MenuRepositoryView = React.lazy(() => import('./views/menuRepositoryView'));
const MenuAdminView = React.lazy(() => import('./views/menuAdminView'));

function ScrollToTop() {
    const {pathname} = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

function App() {
    const GoogleUniversalAnalyticsId = "UA-208043-47";
    const debugRouter = (process.env.NODE_ENV === 'development');
    const [cookies] = useCookies(['visualsource_session']);

    if (typeof window.ga === 'function') {
        if (cookies['visualsource_session'] !== undefined) {
            window.ga('set', 'dimension1', cookies['visualsource_session']);
        }
    }

    return (
        <Router>
            <ScrollToTop/>
            <Analytics id={GoogleUniversalAnalyticsId} debug={debugRouter}>
                <div className="show-container">
                    <Container>
                        <Header>
                            <VisualSourceNavbar/>
                        </Header>
                        <Switch>
                            <Route path="/api/:command+">
                                <h1 style={{margin: "60px", color: "red"}}>This means your real endpoint is not being
                                    called.</h1>
                            </Route>
                            <Route path="/create">
                                <Container className="mainContent mainFixed">
                                    <Content>
                                        <CreateVideo/>
                                    </Content>
                                </Container>
                            </Route>
                            <Route path="/repo/:repo+">
                                <Container className="mainContent">
                                    <Content>
                                        <RepoView/>
                                    </Content>
                                </Container>
                            </Route>
                            <Route path="/menu/repo/:repo+">
                                <Container className="mainContent">
                                    <Content>
                                        <MenuRepoView/>
                                    </Content>
                                </Container>
                            </Route>
                            <Route path="/view/:jobId">
                                <Container className="mainContent mainFixed">
                                    <Content>
                                        <View/>
                                    </Content>
                                    <Sidebar>
                                        <ViewSidebar/>
                                    </Sidebar>
                                </Container>
                            </Route>
                            <Route path="/menu/account">
                                <Container className="mainContent">
                                    <Content>
                                        <Suspense fallback={<Loader backdrop={true}></Loader>}>
                                            <MenuAccountView/>
                                        </Suspense>
                                    </Content>
                                </Container>
                            </Route>
                            <Route path="/menu/repositories">
                                <Container className="mainContent">
                                    <Content>
                                        <Suspense fallback={<Loader backdrop={true}></Loader>}>
                                            <MenuRepositoryView/>
                                        </Suspense>
                                    </Content>
                                </Container>
                            </Route>
                            <Route path="/menu/admin">
                                <Content className="mainContent">
                                    <Suspense fallback={<Loader backdrop={true}></Loader>}>
                                        <MenuAdminView/>
                                    </Suspense>
                                </Content>
                            </Route>
                            <Route path="/menu">
                                <Container className="mainContent">
                                    <Content>
                                        <Suspense fallback={<Loader backdrop={true}></Loader>}>
                                            <MenuView/>
                                        </Suspense>
                                    </Content>
                                </Container>
                            </Route>
                            <Route path="/login">
                                <Container className="mainContent">
                                    <Content>
                                        <LoginView/>
                                    </Content>
                                </Container>
                            </Route>
                            <Route path="/logout">
                                <Container className="mainContent">
                                    <Content>
                                        <LogoutView/>
                                    </Content>
                                </Container>
                            </Route>
                            <Route path="/docs">
                                <Content className="mainContent">
                                    <Suspense fallback={<Loader backdrop={true}></Loader>}>
                                        <Docs/>
                                    </Suspense>
                                </Content>
                            </Route>
                            <Route path="/terms">
                                <Content className="mainContent">
                                    <Suspense fallback={<Loader backdrop={true}></Loader>}>
                                        <Terms/>
                                    </Suspense>
                                </Content>
                            </Route>
                            <Route path="/status">
                                <Content className="mainContent">
                                    <Suspense fallback={<Loader backdrop={true}></Loader>}>
                                        <Status/>
                                    </Suspense>
                                </Content>
                            </Route>
                            <Route path="/privacy">
                                <Content className="mainContent">
                                    <Suspense fallback={<Loader backdrop={true}></Loader>}>
                                        <Privacy/>
                                    </Suspense>
                                </Content>
                            </Route>
                            <Route path="/">
                                <Content className="mainContent">
                                    <Home/>
                                </Content>
                            </Route>
                        </Switch>
                        <VisualSourceFooter/>
                    </Container>
                </div>
            </Analytics>
        </Router>
    );
}

export default App;
