import Nav from '@rsuite/responsive-nav';
import {Button, Navbar} from "rsuite";
import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";

function VisualSourceNavbar() {

    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        fetch("/api/v1/account/active")
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.active) {
                        setIsActive(true);
                    }
                }
            )

    }, [])

    if (isActive) {
        return (
            <Navbar className="navbar-fixed">
                <div className="navBarFixed">
                    <Navbar.Header>
                        <Link to={'/menu'} className="visualsource-logo">
                            visualsource.net
                        </Link>
                    </Navbar.Header>
                    <Navbar.Body>
                        <Nav pullRight className="desktopMenu">
                            <Nav.Item componentClass={Link} to={'/menu'}>
                                <Button
                                    appearance="primary"
                                    size='xs'>
                                    Main Menu
                                </Button>
                            </Nav.Item>
                            <Nav.Item componentClass={Link} to={'/logout'} className="loginButton">
                                Logout
                            </Nav.Item>
                        </Nav>
                    </Navbar.Body>
                </div>
            </Navbar>
        )
    }

    return (
        <Navbar className="navbar-fixed">
            <div className="navBarFixed">
                <Navbar.Header>
                    <Link to={'/'} className="visualsource-logo">
                        visualsource.net
                    </Link>
                </Navbar.Header>
                <Navbar.Body>
                    <Nav pullRight className="desktopMenu">
                        <Nav.Item componentClass={Link} to={'/create'}>
                            <Button
                                appearance="primary"
                                size='xs'>
                                Create Video
                            </Button>
                        </Nav.Item>
                        <Nav.Item componentClass={Link} to={'/login'} className="loginButton">
                            Login
                        </Nav.Item>
                    </Nav>
                    <Nav pullRight className="mobileMenu">
                        <Nav.Item componentClass={Link} to={'/create'}>
                            <Button
                                appearance="primary"
                                size='xs'>
                                +
                            </Button>
                        </Nav.Item>
                    </Nav>
                </Navbar.Body>
            </div>
        </Navbar>
    )
}

export default VisualSourceNavbar;
