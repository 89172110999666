import {Footer} from "rsuite";
import React from "react";
import {Link} from "react-router-dom";

function VisualSourceFooter() {
    return (
        <Footer className="footer">
            {new Date().getFullYear()} &copy; <a href={"https://www.squidcode.com"}>squidcode llc</a>
            &nbsp;| <Link to={"/docs"}>docs</Link>
            &nbsp;| <Link to={"/privacy"}>privacy policy</Link>
            &nbsp;| <Link to={"/terms"}>terms & conditions</Link>
            &nbsp;| <a href="https://github.com/squidcode/visualsource-feedbacks/discussions">support</a>
        </Footer>
    )
}

export default VisualSourceFooter;