import React from "react";
import ta from "time-ago";

function displayDate(date) {
    if (date === undefined) {
        return 'n/a';
    }
    let datePart = date.substr(0,10);
    let timePart = date.substr(11,8);
    date = datePart + "T" + timePart + ".000Z"; // safari only understands ECMA-262 date format
    let dateString = new Date(date).toString();
    return ta.ago(dateString);
}

function VideoMetaData(props) {
    let displayContributors = 'several contributors';
    let dateDiff = 'unknown';

    if (props.video.repository_data) {
        let repoData = JSON.parse(props.video.repository_data);
        if (repoData.total_contributors) {
            let contributors = repoData.total_contributors;
            if (contributors > 1) {
                displayContributors = contributors + ' contributors';
            } else {
                displayContributors = contributors + ' contributor';
            }
        }
        if ((repoData.date_from) && (repoData.date_to)) {
            let diff = repoData.date_to - repoData.date_from;
            if (diff >= 31536000) {
                let years = Math.floor(diff / 31536000);
                if (years > 1) {
                    dateDiff = years + " years";
                } else {
                    dateDiff = years + " year";
                }
            } else if (diff >= 2592000) {
                let months = Math.floor(diff / 2592000);
                if (months > 1) {
                    dateDiff = months + " months";
                } else {
                    dateDiff = months + " month";
                }
            } else {
                let days = Math.floor(diff / 86400);
                if (days > 1) {
                    dateDiff = days + " days";
                } else {
                    dateDiff = days + " day";
                }
            }
        }
    }
    return (
        <div>
            <div className="videoPosted">
                video created: {displayDate(props.video.job_start)}
            </div>
            <div className="videoPosted">
                project length: {dateDiff}
            </div>
            <div className="videoPosted">
                {displayContributors}
            </div>
        </div>
    )
}

export default VideoMetaData;