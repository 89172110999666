import React, {useState} from "react";
import {
    Panel,
    FormGroup,
    Form,
    ControlLabel,
    FormControl,
    HelpBlock,
    ButtonToolbar, Button
} from "rsuite";
import {Link, Redirect} from "react-router-dom";
import {normalizeProtocol, removeProtocol} from "../utils/utils";

function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
}

function CreateVideo() {

    const [repoUrl, setRepoUrl] = useState('');
    const [redirect, setRedirect] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    let changeRepoUrl = (event) => {
        setRepoUrl(event);
        setErrorMessage('');
    }

    let handleSubmit = () => {
        let fullUrl = normalizeProtocol(repoUrl);
        if (!validURL(fullUrl)) {
            setErrorMessage('Invalid repository URL!');
            return;
        }

        // @todo: make it a regexp; match only the beginning, but also make it case insensitive.
        fullUrl = fullUrl.replace('www.github.com', 'github.com');
        setRepoUrl(fullUrl);
        setRedirect(true);
    }

    if (redirect === true) {
        return <Redirect to={'/repo/' + removeProtocol(repoUrl)}/>
    }

    return (
        <Panel bordered className="createVideo">
            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <ControlLabel>Git Repository URL</ControlLabel>
                    <FormControl
                        name="repoUrl"
                        value={repoUrl}
                        onChange={changeRepoUrl}
                        errorMessage={errorMessage}
                        errorPlacement="topEnd"
                    />
                    <HelpBlock>Please enter the full URL of the GIT repository.</HelpBlock>
                </FormGroup>
                <FormGroup>
                    <ButtonToolbar>
                        <Button appearance="primary" onClick={handleSubmit}>Create Video</Button>
                        <Link to={'/'}>
                            <Button appearance="default">Cancel</Button>
                        </Link>
                    </ButtonToolbar>
                </FormGroup>
            </Form>

        </Panel>
    );
}

export default CreateVideo;