import React, {useEffect, useState} from "react";
import {Loader, Message} from "rsuite";

function LoginView() {

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);

    useEffect(() => {
        fetch("/api/v1/login")
            .then(res => res.json())
            .then(
                (result) => {
                    setItems(result);
                    setIsLoaded(true);
                },
                (error) => {
                    setError(error);
                    setIsLoaded(true);
                }
            )

    }, [])

    if (error) {
        return <div className="loginError">
            <Message
                showIcon
                type="error"
                title="Can't login this time"
                description="Please try again later"
            />
        </div>
    } else if (!isLoaded) {
        return <Loader backdrop size="lg"/>
    }

    window.location = items.github_auth_url;

    return (
        <div>
            <Loader backdrop size="lg"/>
        </div>
    )
}

export default LoginView;